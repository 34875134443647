/*! calendar bootstrap theme variables */
/*! component icons */
.e-calendar .e-header .e-date-icon-prev::before {
  content: '\e910';
}

.e-calendar .e-header .e-date-icon-next::before {
  content: '\e916';
}

/*! calendar layout */
ejs-calendar {
  display: block;
}

.e-calendar {
  -webkit-tap-highlight-color: transparent;
  border-radius: 4px;
  display: block;
  overflow: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.e-calendar.e-rtl .e-header .e-title {
  float: right;
  text-align: right;
}

.e-calendar.e-rtl .e-header .e-icon-container {
  float: left;
}

.e-calendar .e-header {
  background: none;
  display: table;
  font-weight: normal;
  position: relative;
  text-align: center;
  width: 100%;
}

.e-calendar .e-header button {
  background: transparent;
  border: 0;
  padding: 0;
  text-decoration: none;
}

.e-calendar .e-header span {
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
  padding: 10px;
  vertical-align: middle;
}

.e-calendar .e-header span.e-disabled {
  cursor: default;
}

.e-calendar .e-week-header {
  padding: 0 0 6px;
}

.e-calendar th {
  cursor: default;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
}

.e-calendar .e-content .e-selected,
.e-calendar .e-content .e-state-hover {
  border-radius: 0;
}

.e-calendar .e-content span.e-day {
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}

.e-calendar .e-content th,
.e-calendar .e-content td {
  box-sizing: border-box;
}

.e-calendar .e-content td.e-disabled {
  opacity: 1;
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-calendar .e-content td {
  cursor: pointer;
  padding: 0;
  text-align: center;
}

.e-calendar .e-content td.e-week-number {
  color: #333;
  font-family: normal;
  font-size: 14px;
  font-weight: 500;
}

.e-calendar .e-content td.e-overlay {
  background: none;
}

.e-calendar .e-content table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 0;
  float: left;
  margin: 0;
  outline: 0;
  padding: 0 0 10px;
  table-layout: fixed;
  width: 100%;
}

.e-calendar .e-content td.e-other-month > span.e-day {
  display: block;
  font-weight: normal;
}

.e-calendar .e-content tr.e-month-hide {
  display: none;
  font-weight: normal;
}

.e-calendar .e-content tr.e-month-hide,
.e-calendar .e-content td.e-other-month {
  pointer-events: initial;
  -ms-touch-action: initial;
      touch-action: initial;
}

.e-calendar .e-content tr.e-month-hide,
.e-calendar .e-content td.e-other-month.e-disabled {
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-calendar .e-content td.e-week-number:hover span.e-day,
.e-calendar .e-content td.e-week-number:hover {
  background-color: #fff;
  cursor: default;
}

.e-calendar .e-header .e-prev,
.e-calendar .e-header .e-next {
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
}

.e-calendar .e-header .e-title {
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.e-calendar .e-header .e-title {
  margin-left: 8px;
}

.e-calendar .e-header .e-prev:hover,
.e-calendar .e-header .e-next:hover {
  cursor: pointer;
}

.e-calendar .e-header .e-prev.e-overlay,
.e-calendar .e-header .e-next.e-overlay {
  background: none;
}

.e-calendar .e-header.e-decade .e-title,
.e-calendar .e-header.e-year .e-title {
  margin-left: 15px;
}

.e-calendar .e-header.e-decade .e-title {
  cursor: default;
}

.e-calendar .e-header .e-icon-container {
  display: inline-block;
  float: right;
}

.e-calendar .e-footer-container {
  text-transform: uppercase;
}

.e-calendar {
  max-width: 262px;
  min-width: 256px;
  padding: 12px;
}

.e-calendar.e-week-number {
  min-width: 300px;
}

.e-calendar.e-week {
  max-width: 294px;
  min-width: 288px;
}

.e-calendar .e-header .e-title {
  line-height: 36px;
}

.e-calendar.e-rtl .e-header .e-title {
  text-align: right;
  text-indent: 4px;
}

.e-calendar .e-header {
  height: 36px;
}

.e-calendar .e-header.e-month {
  padding: 0;
}

.e-calendar .e-header.e-year, .e-calendar .e-header.e-decade {
  padding: 0 0 10px;
}

.e-calendar th {
  font-weight: 600;
  height: 28px;
}

.e-calendar .e-content .e-selected,
.e-calendar .e-content .e-state-hover {
  border-radius: 0;
}

.e-calendar .e-content span.e-day {
  border: none;
  font-size: 14px;
  font-weight: normal;
  height: 28px;
  line-height: 28px;
  width: 34px;
}

.e-calendar .e-content.e-year table,
.e-calendar .e-content.e-decade table {
  border-spacing: 0;
  padding: 0;
}

.e-calendar .e-content.e-month td {
  height: 28px;
  padding: 0;
}

.e-calendar .e-content .tfooter > tr > td {
  height: 36px;
  line-height: 36px;
}

.e-calendar .e-content.e-year td,
.e-calendar .e-content.e-decade td {
  height: 54px;
  padding: 0;
}

.e-calendar .e-content.e-year td > span.e-day,
.e-calendar .e-content.e-decade td > span.e-day {
  font-weight: normal;
  height: 54px;
  line-height: 54px;
  width: 58px;
}

.e-calendar .e-header .e-icon-container .e-prev,
.e-calendar .e-header .e-icon-container .e-next {
  height: 36px;
  width: 36px;
}

.e-calendar .e-footer-container {
  cursor: default;
  display: inline-block;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 10px 0 0 0;
  text-align: center;
  width: 100%;
}

.e-bigger.e-calendar,
.e-bigger .e-calendar {
  max-width: 284px;
  min-width: 278px;
  padding: 16px;
}

.e-bigger.e-calendar.e-week,
.e-bigger .e-calendar.e-week {
  max-width: 320px;
  min-width: 314px;
}

.e-bigger.e-calendar.e-week-number,
.e-bigger .e-calendar.e-week-number {
  min-width: 325px;
}

.e-bigger.e-calendar .e-header .e-title,
.e-bigger .e-calendar .e-header .e-title {
  font-size: 18px;
  line-height: 40px;
  width: 60%;
}

.e-bigger.e-calendar.e-rtl .e-header .e-title,
.e-bigger .e-calendar.e-rtl .e-header .e-title {
  line-height: 40px;
  text-indent: 6px;
}

.e-bigger.e-calendar .e-header,
.e-bigger .e-calendar .e-header {
  height: 40px;
  padding: 0;
}

.e-bigger.e-calendar .e-header span,
.e-bigger .e-calendar .e-header span {
  font-size: 18px;
  padding: 11px;
}

.e-bigger.e-calendar .e-header.e-year, .e-bigger.e-calendar .e-header.e-decade,
.e-bigger .e-calendar .e-header.e-year,
.e-bigger .e-calendar .e-header.e-decade {
  padding: 0 0 10px;
}

.e-bigger.e-calendar th,
.e-bigger .e-calendar th {
  font-size: 15px;
  height: 30px;
}

.e-bigger.e-calendar .e-content.e-year span.e-day,
.e-bigger .e-calendar .e-content.e-year span.e-day {
  font-size: 15px;
  font-weight: normal;
}

.e-bigger.e-calendar .e-content.e-month table,
.e-bigger .e-calendar .e-content.e-month table {
  padding: 0;
}

.e-bigger.e-calendar .e-content.e-year table,
.e-bigger.e-calendar .e-content.e-decade table,
.e-bigger .e-calendar .e-content.e-year table,
.e-bigger .e-calendar .e-content.e-decade table {
  padding: 0 0 10px;
}

.e-bigger.e-calendar .e-content .e-selected,
.e-bigger.e-calendar .e-content .e-state-hover,
.e-bigger .e-calendar .e-content .e-selected,
.e-bigger .e-calendar .e-content .e-state-hover {
  border-radius: 0;
}

.e-bigger.e-calendar .e-content span.e-day,
.e-bigger .e-calendar .e-content span.e-day {
  font-size: 15px;
  height: 30px;
  line-height: 30px;
  width: 36px;
}

.e-bigger.e-calendar .e-content.e-month td,
.e-bigger .e-calendar .e-content.e-month td {
  height: 0;
  padding: 0;
}

.e-bigger.e-calendar .e-content.e-year td,
.e-bigger.e-calendar .e-content.e-decade td,
.e-bigger .e-calendar .e-content.e-year td,
.e-bigger .e-calendar .e-content.e-decade td {
  height: 56px;
  padding: 0;
}

.e-bigger.e-calendar .e-content.e-year td > span.e-day,
.e-bigger.e-calendar .e-content.e-decade td > span.e-day,
.e-bigger .e-calendar .e-content.e-year td > span.e-day,
.e-bigger .e-calendar .e-content.e-decade td > span.e-day {
  height: 56px;
  line-height: 56px;
  width: 62px;
}

.e-bigger.e-calendar .e-header .e-icon-container .e-prev,
.e-bigger.e-calendar .e-header .e-icon-container .e-next,
.e-bigger .e-calendar .e-header .e-icon-container .e-prev,
.e-bigger .e-calendar .e-header .e-icon-container .e-next {
  height: 40px;
  width: 40px;
}

.e-calendar .e-btn.e-today.e-flat.e-disabled,
.e-calendar .e-btn.e-today.e-flat.e-disabled:hover,
.e-calendar .e-btn.e-today.e-flat.e-disabled:active,
.e-calendar .e-btn.e-today.e-flat.e-disabled:focus,
.e-calendar .e-btn.e-today.e-flat.e-disabled:hover:active {
  background: #fff;
  border-color: rgba(204, 204, 204, 0.65);
  box-shadow: none;
  color: rgba(51, 51, 51, 0.65);
  cursor: default;
  opacity: 1;
  outline: none;
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-calendar {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.e-calendar .e-date-icon-prev,
.e-calendar .e-date-icon-next {
  color: #333;
}

.e-calendar th {
  border-bottom: 0;
  color: #333;
}

.e-calendar .e-header {
  border-bottom: 0;
}

.e-calendar .e-header a span {
  border: none;
  color: #333;
}

.e-calendar .e-header .e-title {
  color: #333;
}

.e-calendar .e-header .e-title:hover {
  color: #333;
  cursor: pointer;
  text-decoration: none;
}

.e-calendar .e-header .e-prev:hover > span,
.e-calendar .e-header .e-next:hover > span {
  border: none;
  color: #333;
  cursor: pointer;
}

.e-calendar .e-header .e-prev:hover,
.e-calendar .e-header .e-next:hover {
  background: #e6e6e6;
}

.e-calendar .e-header .e-prev:active,
.e-calendar .e-header .e-next:active {
  background: #d4d4d4;
  color: #fff;
}

.e-calendar .e-header button.e-prev:active span,
.e-calendar .e-header button.e-next:active span {
  border: 1px solid transparent;
  color: #333;
}

.e-calendar .e-header.e-decade .e-title {
  color: #333;
  cursor: default;
}

.e-calendar .e-header .e-next.e-disabled span,
.e-calendar .e-header .e-prev.e-disabled span {
  color: #ccc;
  font-weight: normal;
}

.e-calendar .e-header .e-next.e-disabled,
.e-calendar .e-header .e-prev.e-disabled {
  opacity: 1;
}

.e-calendar .e-content.e-decade tr:first-child .e-cell:first-child span.e-day,
.e-calendar .e-content.e-decade tr:last-child .e-cell:last-child span.e-day {
  color: rgba(51, 51, 51, 0.65);
}

.e-calendar .e-content.e-decade tr:first-child .e-cell:first-child.e-selected span.e-day,
.e-calendar .e-content.e-decade tr:last-child .e-cell:last-child.e-selected span.e-day {
  color: #fff;
}

.e-calendar .e-content.e-decade tr:first-child .e-cell.e-disabled:first-child span.e-day,
.e-calendar .e-content.e-decade tr:last-child .e-cell.e-disabled:last-child span.e-day {
  color: #ccc;
}

.e-calendar .e-content.e-year td:hover span.e-day,
.e-calendar .e-content.e-decade td:hover span.e-day {
  background-color: #e6e6e6;
}

.e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-calendar .e-content.e-decade td.e-selected:hover span.e-day {
  background-color: #265f91;
}

.e-calendar .e-content.e-year td > span.e-day,
.e-calendar .e-content.e-decade td > span.e-day {
  background: none;
}

.e-calendar .e-content .e-week-number span {
  color: #999;
}

.e-calendar .e-content td.e-focused-date span.e-day,
.e-calendar .e-content td.e-focused-date:hover span.e-day,
.e-calendar .e-content td.e-focused-date:focus span.e-day {
  background: #e6e6e6;
  border: none;
  border-radius: 4px;
}

.e-calendar .e-content td.e-focused-date:hover span.e-day {
  background-color: #e6e6e6;
  border: none;
  border-radius: 4px;
  color: #333;
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: none;
  border: 1px solid #317ab9;
  border-radius: 4px;
  color: #333;
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: #e6e6e6;
  border: 1px solid #317ab9;
  color: #333;
}

.e-calendar .e-content td.e-today:focus span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  background-color: #e6e6e6;
  border: none;
  border-radius: 4px;
  color: #333;
}

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  background-color: #e6e6e6;
  border: 1px solid #317ab9;
  color: #333;
}

.e-calendar .e-content td.e-today.e-selected span.e-day {
  background-color: #317ab9;
  border: 1px solid #317ab9;
  box-shadow: none;
  color: #fff;
}

.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: #265f91;
  color: #fff;
}

.e-calendar .e-content span {
  color: #333;
}

.e-calendar .e-content .e-disabled span.e-day:hover {
  background: none;
  border: 0;
  color: #ccc;
}

.e-calendar .e-content .e-other-month:hover span.e-day {
  color: rgba(51, 51, 51, 0.65);
}

.e-calendar .e-content .e-other-month span.e-day,
.e-calendar .e-content .e-other-month.e-today span.e-day {
  color: rgba(51, 51, 51, 0.65);
}

.e-calendar .e-content .e-other-month.e-today:hover span.e-day {
  background-color: #e6e6e6;
  color: rgba(51, 51, 51, 0.65);
}

.e-calendar .e-content thead {
  background: none;
  border-bottom: 0;
}

.e-calendar .e-content td:hover span.e-day,
.e-calendar .e-content td:focus span.e-day {
  background-color: #e6e6e6;
  border: none;
  border-radius: 4px;
  color: #333;
}

.e-calendar .e-content td:focus span.e-day {
  background-color: #e6e6e6;
  border: none;
  border-radius: 4px;
  color: #333;
}

.e-calendar .e-content td.e-disabled span.e-day,
.e-calendar .e-content td.e-disabled:hover span.e-day,
.e-calendar .e-content td.e-disabled:focus span.e-day {
  background: none;
  border: none;
  color: #ccc;
}

.e-calendar .e-content td.e-selected span.e-day {
  background-color: #317ab9;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
}

.e-calendar .e-content .e-footer {
  color: #317ab9;
}

.e-calendar.e-device .e-prev:hover,
.e-calendar.e-device .e-next:hover,
.e-calendar.e-device .e-prev:active,
.e-calendar.e-device .e-next:active,
.e-calendar.e-device .e-prev:focus,
.e-calendar.e-device .e-next:focus {
  background: none;
}

.e-calendar.e-device button.e-prev:active span,
.e-calendar.e-device button.e-next:active span {
  color: #333;
}

.e-zoomin {
  animation: animatezoom .3s;
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/*! component icons */
.e-time-wrapper .e-time-icon.e-icons::before,
.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons::before {
  content: '\e93e';
}

.e-time-wrapper,
.e-control-wrapper.e-time-wrapper {
  -webkit-tap-highlight-color: transparent;
}

.e-time-wrapper .e-time-icon.e-icons,
.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons {
  font-size: 16px;
  min-height: 34px;
  min-width: 34px;
}

.e-time-wrapper .e-time-icon.e-icons.e-disabled,
.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons.e-disabled {
  pointer-events: none;
}

.e-time-wrapper span,
.e-control-wrapper.e-time-wrapper span {
  cursor: pointer;
}

.e-timepicker.e-time-modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-timepicker.e-popup {
  border-style: solid;
  border-width: 1px;
  overflow: auto;
}

.e-timepicker.e-popup .e-content {
  position: relative;
}

.e-timepicker.e-popup .e-list-parent.e-ul {
  margin: 0;
  padding: 0 0;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  cursor: default;
  font-size: 14px;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover {
  cursor: pointer;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  line-height: 26px;
  text-indent: 20px;
}

.e-timepicker.e-popup.e-rtl .e-list-parent.e-ul .e-list-item {
  text-align: right;
}

.e-bigger .e-time-wrapper .e-time-icon.e-icons,
.e-bigger.e-time-wrapper .e-time-icon.e-icons,
.e-bigger .e-control-wrapper .e-time-wrapper .e-time-icon.e-icons,
.e-control-wrapper.e-bigger.e-time-wrapper .e-time-icon.e-icons {
  font-size: 16px;
  min-height: 40px;
  min-width: 40px;
}

.e-bigger .e-timepicker.e-popup .e-list-parent.e-ul,
.e-bigger.e-timepicker.e-popup .e-list-parent.e-ul {
  padding: 0 0;
}

.e-bigger .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-bigger.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 15px;
  line-height: 40px;
  text-indent: 20px;
}

/*! timepicker theme */
.e-time-wrapper .e-input-group-icon.e-icons.e-active {
  color: #333;
}

.e-time-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: #333;
}

.e-timepicker.e-popup {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(51, 51, 51, 0.175);
}

.e-timepicker.e-popup .e-list-parent.e-ul {
  background-color: #fff;
}

.e-timepicker.e-popup .e-list-parent.e-ul li.e-list-item {
  border: none;
  color: #333;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-disabled {
  color: rgba(85, 85, 85, 0.65);
  opacity: 1;
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover,
.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-navigation,
.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item:focus {
  background-color: #f5f5f5;
  border: none;
  color: #333;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #317ab9;
  color: #fff;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #f5f5f5;
  color: #333;
}

/*! calendar bootstrap theme variables */
/*! component icons */
.e-input-group-icon.e-date-icon,
.e-control-wrapper .e-input-group-icon.e-date-icon {
  font-size: 16px;
  margin: 0;
  min-height: 32px;
  min-width: 32px;
  outline: none;
}

.e-input-group-icon.e-date-icon::before,
.e-control-wrapper .e-input-group-icon.e-date-icon::before {
  content: '\e960';
  font-family: 'e-icons';
}

.e-input-group-icon.e-date-icon:focus,
.e-control-wrapper .e-input-group-icon.e-date-icon:focus {
  background: #e6e6e6;
  border-radius: 0;
}

.e-bigger .e-input-group-icon.e-date-icon,
.e-control-wrapper.e-bigger .e-input-group-icon.e-date-icon,
.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon {
  font-size: 20px;
  margin: 0;
  min-height: 38px;
  min-width: 38px;
}

.e-bigger .e-input-group-icon.e-date-icon::before,
.e-control-wrapper.e-bigger .e-input-group-icon.e-date-icon::before,
.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon::before {
  content: '\e960';
  font-family: 'e-icons';
}

.e-bigger .e-input-group-icon.e-date-icon:focus,
.e-control-wrapper.e-bigger .e-input-group-icon.e-date-icon:focus,
.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon:focus {
  background: #e6e6e6;
  border-radius: 0;
}

.e-date-wrapper .e-date-icon.e-icons {
  box-sizing: border-box;
}

.e-datepicker .e-calendar .e-content table tbody tr.e-month-hide:last-child {
  display: table-row;
}

.e-datepicker.e-popup-wrapper {
  border-radius: 4px;
  overflow-y: hidden;
  pointer-events: auto;
}

.e-datepicker.e-date-modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-datepicker .e-model-header {
  background-color: none;
  color: none;
  cursor: default;
  display: none;
  padding: 10px 10px 10px 15px;
}

.e-datepicker .e-model-header .e-model-year {
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
}

.e-datepicker .e-model-month,
.e-datepicker .e-model-day {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
}

.e-date-overflow {
  overflow: hidden !important;
}

@media screen and (orientation: landscape) and (max-device-height: 360px) {
  .e-datepicker .e-calendar .e-month table tbody {
    display: inline-block;
    height: 150px;
    overflow: auto;
  }
}

.e-datepicker.e-popup-wrapper {
  border: 1px solid #ccc;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.e-datepicker .e-calendar {
  background-color: #fff;
  border: none;
}

.e-input-group.e-date-wrapper.e-dateinput-active:active:not(.e-success):not(.e-warning):not(.e-error) {
  border: none;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons:hover:not(:active) {
  background: "";
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: none;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: none;
}

/*! calendar bootstrap theme variables */
/*! component icons */
.e-input-group-icon.e-range-icon,
.e-control-wrapper .e-input-group-icon.e-range-icon {
  font-size: 16px;
  margin: 0;
  min-height: 34px;
  min-width: 34px;
  outline: none;
}

.e-input-group-icon.e-range-icon::before,
.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: '\e243';
  font-family: 'e-icons';
}

.e-input-group-icon.e-range-icon:focus,
.e-control-wrapper .e-input-group-icon.e-range-icon:focus {
  background: #e6e6e6;
  border-radius: 0;
}

.e-daterangepicker .e-calendar .e-header .e-date-icon-next::before {
  content: '\e913';
}

.e-daterangepicker .e-calendar .e-header .e-date-icon-prev::before {
  content: '\e904';
}

.e-daterangepicker .e-change-icon::before {
  content: '\e85f';
}

.e-daterangepicker .e-calendar.e-rtl .e-header .e-date-icon-next::before {
  content: '\e98f';
}

.e-daterangepicker .e-calendar.e-rtl .e-header .e-date-icon-prev::before {
  content: '\e85c';
}

.e-daterangepicker.e-rtl .e-start-end .e-change-icon::before {
  content: '\e85b';
}

.e-bigger .e-input-group-icon.e-range-icon,
.e-bigger.e-control-wrapper .e-input-group-icon.e-range-icon,
.e-bigger .e-control-wrapper .e-input-group-icon.e-range-icon {
  font-size: 20px;
  margin: 0;
  min-height: 34px;
  min-width: 34px;
  outline: none;
}

/*! daterangepicker layout */
.e-daterangepicker.e-popup {
  border: 1px solid rgba(245, 245, 245, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  max-height: 500px;
  max-width: 730px;
}

.e-daterangepicker.e-popup.e-preset-wrapper {
  min-width: 659px;
}

.e-daterangepicker.e-popup.e-preset-wrapper .e-presets {
  max-height: none;
}

.e-daterangepicker.e-popup .e-range-header {
  margin: 12px 12px 0 12px;
  width: auto;
}

.e-daterangepicker.e-popup .e-range-header .e-start-label,
.e-daterangepicker.e-popup .e-range-header .e-end-label {
  cursor: default;
  display: inline-block;
  font-size: 20px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  width: 48%;
}

.e-daterangepicker.e-popup .e-range-header .e-change-icon {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  width: 4%;
}

.e-daterangepicker.e-popup .e-range-header .e-day-span {
  direction: ltr;
  font-size: 13px;
  height: 14px;
  margin: 0 0 13px 0;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}

.e-daterangepicker.e-popup .e-range-header .e-start-end {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
}

.e-daterangepicker.e-popup .e-separator {
  height: 1px;
  margin: 0 16px;
}

.e-daterangepicker.e-popup .e-calendar {
  border: none;
  margin: 0;
  padding: 0;
}

.e-daterangepicker.e-popup .e-calendar .e-content table {
  padding: 0 10px 10px;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-title {
  cursor: pointer;
  float: none;
  font-weight: normal;
  line-height: 36px;
  margin-left: 0;
  width: auto;
}

.e-daterangepicker.e-popup .e-calendar .e-header.e-month, .e-daterangepicker.e-popup .e-calendar .e-header.e-year, .e-daterangepicker.e-popup .e-calendar .e-header.e-decade {
  padding: 10px 10px 0 10px;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-next {
  float: right;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-prev {
  float: left;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-next,
.e-daterangepicker.e-popup .e-calendar .e-header .e-prev {
  height: 36px;
  width: 36px;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-next span,
.e-daterangepicker.e-popup .e-calendar .e-header .e-prev span {
  padding: 10px;
}

.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover {
  border-radius: 50% 0 0 50%;
}

.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover {
  border-radius: 0 50% 50% 0;
}

.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day {
  border: none;
}

.e-daterangepicker.e-popup .e-footer {
  -ms-flex-align: center;
      align-items: center;
  border-top: 1px solid rgba(51, 51, 51, 0.12);
  clear: both;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
  height: 48px;
}

.e-daterangepicker.e-popup .e-footer .e-btn {
  font-weight: 500;
  height: 34px;
  line-height: 32px;
  overflow: hidden;
  padding: 0 16px;
  text-overflow: ellipsis;
}

.e-daterangepicker.e-popup .e-footer .e-btn.e-apply {
  margin: 0 10px 0 5px;
}

.e-daterangepicker.e-popup .e-date-range-container {
  float: left;
}

.e-daterangepicker.e-popup .e-date-range-container.e-range-border {
  border-right: 1px solid rgba(51, 51, 51, 0.12);
}

.e-daterangepicker.e-popup .e-calendar-container {
  display: -ms-flexbox;
  display: flex;
}

.e-daterangepicker.e-popup .e-calendar-container .e-left-container,
.e-daterangepicker.e-popup .e-calendar-container .e-right-container {
  float: left;
}

.e-daterangepicker.e-popup .e-calendar-container .e-left-container {
  border-right: 1px solid rgba(51, 51, 51, 0.12);
}

.e-daterangepicker.e-popup .e-presets {
  max-height: 216px;
  overflow: auto;
  width: auto;
}

.e-daterangepicker.e-popup .e-presets .e-list-item {
  border-radius: 4px;
  cursor: pointer;
  line-height: 26px;
  overflow: hidden;
  padding: 0 20px;
  white-space: nowrap;
}

.e-daterangepicker.e-popup .e-presets .e-list-parent {
  margin: 0;
  max-width: 160px;
  padding: 0;
}

.e-daterangepicker.e-popup .e-presets .e-text-content {
  line-height: 47px;
}

.e-daterangepicker.e-popup .e-presets .e-ul li.e-list-item {
  font-size: 14px;
  height: 36px;
  line-height: 36px;
}

.e-daterangepicker.e-popup .e-hide-range {
  display: none;
}

.e-daterangepicker.e-rtl .e-date-range-container {
  float: right;
}

.e-daterangepicker.e-rtl .e-date-range-container.e-range-border {
  border-left: 1px solid rgba(51, 51, 51, 0.12);
  border-right: 0;
}

.e-daterangepicker.e-rtl .e-date-range-container .e-left-container {
  border-left: 1px solid rgba(51, 51, 51, 0.12);
  border-right: 0;
}

.e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-next {
  float: left;
}

.e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-prev {
  float: right;
}

.e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-start-date.e-selected.e-range-hover {
  border-radius: 0 50% 50% 0;
}

.e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-end-date.e-selected.e-range-hover {
  border-radius: 50% 0 0 50%;
}

.e-daterangepicker.e-rtl .e-footer {
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.e-daterangepicker.e-rtl .e-footer .e-btn.e-cancel {
  margin: 0 5px 0 10px;
}

.e-daterangepicker.e-rtl .e-footer .e-btn.e-apply {
  margin-left: 0;
}

.e-bigger .e-daterangepicker.e-range-modal,
.e-device.e-daterangepicker.e-range-modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-bigger .e-daterangepicker.e-popup.e-preset-wrapper,
.e-bigger.e-daterangepicker.e-popup.e-preset-wrapper,
.e-device.e-daterangepicker.e-popup.e-preset-wrapper {
  max-width: 770px;
  min-width: auto;
}

.e-bigger .e-daterangepicker.e-popup.e-preset-wrapper .e-presets,
.e-bigger.e-daterangepicker.e-popup.e-preset-wrapper .e-presets,
.e-device.e-daterangepicker.e-popup.e-preset-wrapper .e-presets {
  max-height: none;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header,
.e-bigger.e-daterangepicker.e-popup .e-range-header,
.e-device.e-daterangepicker.e-popup .e-range-header {
  width: auto;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  -ms-flex-pack: center;
      justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn {
  border: 1px solid #317ab9;
  box-shadow: none;
  font-size: 16px;
  font-weight: normal;
  height: 36px;
  line-height: 1em;
  max-width: 116px;
  overflow: hidden;
  padding: 1px 6px;
  text-overflow: ellipsis;
  width: 50%;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn {
  border-left: 0;
  border-radius: 0 4px 4px 0;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn {
  border-radius: 4px 0 0 4px;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn:hover,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn:hover:not([disabled]),
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn:hover,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn:hover:not([disabled]),
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn:hover,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn:hover:not([disabled]) {
  box-shadow: none;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:active,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:active:not([disabled]),
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:hover,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:hover,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:active,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:active:not([disabled]),
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:hover,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:hover,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:active,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:active:not([disabled]),
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:hover,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:hover {
  box-shadow: none;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn {
  max-width: 141px;
}

.e-bigger .e-daterangepicker.e-popup .e-presets,
.e-bigger.e-daterangepicker.e-popup .e-presets,
.e-device.e-daterangepicker.e-popup .e-presets {
  max-height: 240px;
}

.e-bigger .e-daterangepicker.e-popup .e-presets.e-preset-wrapper,
.e-bigger.e-daterangepicker.e-popup .e-presets.e-preset-wrapper,
.e-device.e-daterangepicker.e-popup .e-presets.e-preset-wrapper {
  max-height: none;
}

.e-bigger .e-daterangepicker.e-popup .e-presets ul,
.e-bigger.e-daterangepicker.e-popup .e-presets ul,
.e-device.e-daterangepicker.e-popup .e-presets ul {
  max-width: none;
}

.e-bigger .e-daterangepicker.e-popup .e-presets ul li.e-list-item,
.e-bigger.e-daterangepicker.e-popup .e-presets ul li.e-list-item,
.e-device.e-daterangepicker.e-popup .e-presets ul li.e-list-item {
  font-size: 15px;
  height: 45px;
  line-height: 45px;
  padding: 0 12px;
}

.e-bigger .e-daterangepicker .e-calendar,
.e-bigger.e-daterangepicker .e-calendar,
.e-device.e-daterangepicker .e-calendar {
  max-width: 292px;
  padding: 0;
}

.e-bigger .e-daterangepicker .e-calendar .e-content table,
.e-bigger.e-daterangepicker .e-calendar .e-content table,
.e-device.e-daterangepicker .e-calendar .e-content table {
  padding: 0 15px 15px;
}

.e-bigger .e-daterangepicker .e-calendar .e-header .e-next,
.e-bigger .e-daterangepicker .e-calendar .e-header .e-prev,
.e-bigger.e-daterangepicker .e-calendar .e-header .e-next,
.e-bigger.e-daterangepicker .e-calendar .e-header .e-prev,
.e-device.e-daterangepicker .e-calendar .e-header .e-next,
.e-device.e-daterangepicker .e-calendar .e-header .e-prev {
  height: 40px;
  width: 40px;
}

.e-bigger .e-daterangepicker .e-calendar .e-header .e-next span,
.e-bigger .e-daterangepicker .e-calendar .e-header .e-prev span,
.e-bigger.e-daterangepicker .e-calendar .e-header .e-next span,
.e-bigger.e-daterangepicker .e-calendar .e-header .e-prev span,
.e-device.e-daterangepicker .e-calendar .e-header .e-next span,
.e-device.e-daterangepicker .e-calendar .e-header .e-prev span {
  padding: 11px;
}

.e-bigger .e-daterangepicker .e-calendar .e-header .e-title,
.e-bigger.e-daterangepicker .e-calendar .e-header .e-title,
.e-device.e-daterangepicker .e-calendar .e-header .e-title {
  cursor: pointer;
  line-height: 40px;
}

.e-bigger .e-daterangepicker .e-calendar .e-header.e-month, .e-bigger .e-daterangepicker .e-calendar .e-header.e-year, .e-bigger .e-daterangepicker .e-calendar .e-header.e-decade,
.e-bigger.e-daterangepicker .e-calendar .e-header.e-month,
.e-bigger.e-daterangepicker .e-calendar .e-header.e-year,
.e-bigger.e-daterangepicker .e-calendar .e-header.e-decade,
.e-device.e-daterangepicker .e-calendar .e-header.e-month,
.e-device.e-daterangepicker .e-calendar .e-header.e-year,
.e-device.e-daterangepicker .e-calendar .e-header.e-decade {
  padding: 10px 10px 0 10px;
}

.e-bigger .e-daterangepicker .e-footer,
.e-bigger.e-daterangepicker .e-footer,
.e-device.e-daterangepicker .e-footer {
  height: 58px;
}

.e-bigger .e-daterangepicker .e-footer .e-btn,
.e-bigger.e-daterangepicker .e-footer .e-btn,
.e-device.e-daterangepicker .e-footer .e-btn {
  height: 40px;
  line-height: 38px;
  overflow: hidden;
}

.e-bigger .e-daterangepicker .e-footer .e-btn.e-apply,
.e-bigger.e-daterangepicker .e-footer .e-btn.e-apply,
.e-device.e-daterangepicker .e-footer .e-btn.e-apply {
  margin: 0 15px 0 5px;
}

.e-bigger .e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-end-btn,
.e-bigger.e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-end-btn,
.e-device.e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-end-btn {
  border: 1px solid #317ab9;
  border-radius: 4px 0 0 4px;
  border-right: 0;
}

.e-bigger .e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-start-btn,
.e-bigger.e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-start-btn,
.e-device.e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-start-btn {
  border-radius: 0 4px 4px 0;
}

.e-bigger .e-daterangepicker.e-rtl.e-popup .e-footer.e-btn.e-cancel,
.e-bigger.e-daterangepicker.e-rtl.e-popup .e-footer.e-btn.e-cancel,
.e-device.e-daterangepicker.e-rtl.e-popup .e-footer.e-btn.e-cancel {
  margin: 0 5px 0 15px;
}

.e-bigger .e-daterangepicker.e-rtl.e-popup .e-footer .e-btn.e-apply,
.e-bigger.e-daterangepicker.e-rtl.e-popup .e-footer .e-btn.e-apply,
.e-device.e-daterangepicker.e-rtl.e-popup .e-footer .e-btn.e-apply {
  margin-left: 0;
}

.e-bigger .e-daterangepicker.e-device.e-popup,
.e-bigger.e-daterangepicker.e-device.e-popup,
.e-device.e-daterangepicker.e-device.e-popup {
  max-width: 294px;
}

.e-bigger .e-daterangepicker.e-device.e-popup .e-range-header,
.e-bigger.e-daterangepicker.e-device.e-popup .e-range-header,
.e-device.e-daterangepicker.e-device.e-popup .e-range-header {
  margin: 20px 10px 0 10px;
}

.e-bigger .e-daterangepicker.e-device.e-popup .e-range-header .e-day-span,
.e-bigger.e-daterangepicker.e-device.e-popup .e-range-header .e-day-span,
.e-device.e-daterangepicker.e-device.e-popup .e-range-header .e-day-span {
  margin: 10px 0;
}

.e-range-overflow {
  overflow: auto;
}

/*! daterangepicker theme */
.e-date-range-wrapper .e-input-group-icon.e-icons.e-active {
  color: #333;
}

.e-date-range-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: #333;
}

.e-daterangepicker.e-popup {
  background: #fff;
}

.e-daterangepicker.e-popup .e-calendar {
  background-color: #fff;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-title,
.e-daterangepicker.e-popup .e-calendar .e-header .e-title:hover {
  color: #333;
  text-decoration: none;
}

.e-daterangepicker.e-popup .e-calendar .e-conten .e-range-hover {
  background-color: #f5f5f5;
  color: #333;
}

.e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span {
  background-color: #f5f5f5;
  border: 1px solid #317ab9;
  color: #333;
}

.e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover span {
  background: #f5f5f5;
  border: none;
  color: #333;
}

.e-daterangepicker.e-popup .e-calendar .e-range-hover:not(.e-selected):hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-focused-date:not(.e-selected) span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-focused-date.e-today span.e-day {
  background-color: #cfcfcf;
  border: none;
  color: #333;
}

.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-today:hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-focused-date.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-start-date.e-selected.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-end-date.e-selected.e-today span.e-day {
  border: 1px solid #317ab9;
}

.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-selected.e-today:hover span.e-day {
  border: 1px solid #317ab9;
}

.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover,
.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover span,
.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover:hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-selected,
.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-selected span {
  background-color: #fff;
  border: none;
  color: #ccc;
}

.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover,
.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-selected {
  background-color: #fff;
  border: none;
  color: #ccc;
}

.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day {
  background-color: #265f91;
  color: #fff;
}

.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today span.e-day {
  background-color: #265f91;
  color: #fff;
}

.e-daterangepicker.e-popup .e-calendar .e-other-month.e-selected span {
  color: #fff;
}

.e-daterangepicker.e-popup .e-presets {
  background-color: #fff;
  color: #333;
}

.e-daterangepicker.e-popup .e-presets .e-list-item.e-active {
  background-color: #317ab9;
  color: #fff;
}

.e-daterangepicker.e-popup .e-presets .e-list-item.e-hover {
  background-color: #f5f5f5;
  color: #333;
}

.e-daterangepicker.e-popup .e-start-label,
.e-daterangepicker.e-popup .e-end-label {
  color: #333;
}

.e-daterangepicker.e-popup .e-change-icon {
  color: #333;
}

.e-daterangepicker.e-popup .e-day-span {
  color: #999;
}

.e-daterangepicker.e-popup .e-separator {
  background-color: rgba(51, 51, 51, 0.12);
}

.e-daterangepicker.e-popup .e-footer {
  background-color: #fff;
}

.e-bigger .e-daterangepicker,
.e-bigger.e-daterangepicker,
.e-device.e-daterangepicker {
  background-color: #fff;
  padding: 0;
}

.e-bigger .e-daterangepicker .e-calendar th,
.e-bigger.e-daterangepicker .e-calendar th,
.e-device.e-daterangepicker .e-calendar th {
  color: #333;
}

.e-bigger .e-daterangepicker .e-start-btn,
.e-bigger .e-daterangepicker .e-end-btn,
.e-bigger.e-daterangepicker .e-start-btn,
.e-bigger.e-daterangepicker .e-end-btn,
.e-device.e-daterangepicker .e-start-btn,
.e-device.e-daterangepicker .e-end-btn {
  background: #fff;
}

.e-bigger .e-daterangepicker .e-start-btn.e-active,
.e-bigger .e-daterangepicker .e-start-btn.e-active:active,
.e-bigger .e-daterangepicker .e-end-btn.e-active,
.e-bigger .e-daterangepicker .e-end-btn.e-active:active:not([disabled]),
.e-bigger .e-daterangepicker .e-start-btn.e-active:hover,
.e-bigger .e-daterangepicker .e-end-btn.e-active:hover,
.e-bigger.e-daterangepicker .e-start-btn.e-active,
.e-bigger.e-daterangepicker .e-start-btn.e-active:active,
.e-bigger.e-daterangepicker .e-end-btn.e-active,
.e-bigger.e-daterangepicker .e-end-btn.e-active:active:not([disabled]),
.e-bigger.e-daterangepicker .e-start-btn.e-active:hover,
.e-bigger.e-daterangepicker .e-end-btn.e-active:hover,
.e-device.e-daterangepicker .e-start-btn.e-active,
.e-device.e-daterangepicker .e-start-btn.e-active:active,
.e-device.e-daterangepicker .e-end-btn.e-active,
.e-device.e-daterangepicker .e-end-btn.e-active:active:not([disabled]),
.e-device.e-daterangepicker .e-start-btn.e-active:hover,
.e-device.e-daterangepicker .e-end-btn.e-active:hover {
  background: #317ab9;
  color: #fff;
}

/*! component icons */
.e-datetime-wrapper .e-time-icon.e-icons::before {
  content: '\e93e';
  font-family: 'e-icons';
}

.e-datetime-wrapper {
  -webkit-tap-highlight-color: transparent;
}

.e-datetime-wrapper .e-time-icon.e-icons::before {
  font-size: 16px;
}

.e-datetime-wrapper.e-control-wrapper {
  box-sizing: border-box;
}

.e-datetime-wrapper .e-time-icon.e-icons.e-disabled,
.e-datetime-wrapper .e-date-icon.e-icons.e-disabled {
  pointer-events: none;
}

.e-datetime-wrapper .e-clear-icon {
  box-sizing: content-box;
}

.e-datetime-wrapper span {
  cursor: pointer;
}

.e-datetime-wrapper .e-input-group-icon.e-date-icon,
.e-datetime-wrapper .e-input-group-icon.e-time-icon {
  font-size: 16px;
  margin: 0;
  min-height: 34px;
  min-width: 34px;
  outline: none;
}

.e-datetime-wrapper .e-input-group-icon.e-time-icon {
  border: 1px;
  border-style: none;
  margin: 0;
}

.e-datetime-wrapper.e-rtl .e-input-group-icon.e-time-icon {
  margin: 0;
}

.e-datetimepicker.e-time-modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-datetimepicker.e-popup {
  border-style: solid;
  border-width: 1px;
  overflow: auto;
}

.e-datetimepicker.e-popup .e-content {
  position: relative;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul {
  margin: 0;
  padding: 5px 0;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  color: #333;
  cursor: default;
  font-size: 14px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover {
  cursor: pointer;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  line-height: 26px;
  text-indent: 20px;
}

.e-bigger.e-datetime-wrapper .e-time-icon.e-icons::before,
.e-bigger .e-datetime-wrapper .e-time-icon.e-icons::before {
  font-size: 20px;
}

.e-bigger.e-datetime-wrapper .e-input-group-icon.e-time-icon,
.e-bigger .e-datetime-wrapper .e-input-group-icon.e-time-icon {
  margin: 0;
  min-height: 40px;
  min-width: 40px;
}

.e-bigger.e-datetime-wrapper.e-rtl .e-input-group-icon.e-time-icon,
.e-bigger .e-datetime-wrapper.e-rtl .e-input-group-icon.e-time-icon {
  margin: 0;
}

.e-bigger .e-datetimepicker.e-popup .e-list-parent.e-ul,
.e-bigger.e-datetimepicker.e-popup .e-list-parent.e-ul {
  padding: 10px 0;
}

.e-bigger .e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-bigger.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 15px;
  line-height: 40px;
  text-indent: 20px;
}

/*! datetimepicker theme */
.e-datetime-wrapper .e-input-group-icon.e-icons.e-active {
  color: #333;
}

.e-datetime-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: #fff;
}

.e-datetimepicker.e-popup {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(51, 51, 51, 0.175);
}

.e-datetimepicker.e-popup .e-list-parent.e-ul {
  background-color: #fff;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul li.e-list-item {
  border: none;
  color: #333;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover,
.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-navigation,
.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item:focus {
  background-color: #f5f5f5;
  border: none;
  color: #333;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #317ab9;
  color: #fff;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #317ab9;
  color: #fff;
}
